<script setup>
import { onMounted, ref } from "vue";
import axios from "axios";
import fileJson from "@/assets/json/file-extension.json";
const getKiosk = async () => {
    try {
        const res = await axios.get(`/api/kiosk `);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
const putKiosk = async (data) => {
    try {
        const res = await axios.put(`/api/kiosk `, data);
        return res.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};
const createFormData = ({ params, files }) => {
    const formData = new FormData();
    formData.append("kioskDescription", params.kioskDescription);
    formData.append("kioskTitle", params.kioskTitle);
    formData.append("kioskImage", files.kiosk);
    formData.append("tabletDescription", params.tabletDescription);
    formData.append("tabletImage", files.tablet);
    formData.append("tabletTitle", params.tabletTitle);
    return formData;
};
const baseDomain = "https://nodeblack.net";
const defaultFileName = "클릭하여 이미지 파일을 선택해주세요.";
const info = ref({
    kioskDescription: "",
    kioskImage: "",
    kioskTitle: "",
    tabletDescription: "",
    tabletImage: "",
    tabletTitle: "",
});
const files = ref({
    kiosk: null,
    tablet: null,
});
const fileNames = ref({
    kiosk: defaultFileName,
    tablet: defaultFileName,
});
const convertFileToBase64 = (file, type) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        fileNames.value[type] = file.name;
        readFileHandler(e.target.result, type); // Base64 데이터 URL
    };
    reader.readAsDataURL(file);
};
const readFileHandler = (file, type) => {
    switch (type) {
        case "kiosk":
            info.value.kioskImage = file;
            break;
        case "tablet":
            info.value.tabletImage = file;
            break;
    }
};
const changeFile = async (file, type) => {
    if (file) {
        const validate = await validateFileExtensionByFileHeader(file);
        if (!validate) {
            alert("파일은 이미지 파일만 선택할 수 있습니다.");
            return false;
        }
        files.value[type] = file;
        convertFileToBase64(file, type);
    }
};
const getSuccessHandler = ({ contents }) => {
    info.value.kioskTitle = contents.kioskTitle;
    info.value.kioskDescription = contents.kioskDescription;
    info.value.kioskImage = baseDomain + contents.kioskImage;
    info.value.tabletTitle = contents.tabletTitle;
    info.value.tabletDescription = contents.tabletDescription;
    info.value.tabletImage = baseDomain + contents.tabletImage;
};
const saveKioskTabletInfo = async () => {
    const formData = createFormData({ params: info.value, files: files.value });
    const response = await putKiosk(formData);
    if (response.err_code === 0) alert("정보 변경에 성공했습니다.");
    else alert("정보 변경에 실패했습니다.");
};
const getFileHeader = (file) => {
    return new Promise((resolve, reject) => {
        const isWebp = file.name.toLowerCase().endsWith(".webp"); // 파일 확장자 확인
        const byteCount = isWebp ? 12 : 4; // webp면 12바이트, 아니면 4바이트

        const reader = new FileReader();

        reader.onloadend = function (e) {
            const uint = new Uint8Array(e.target.result).subarray(0, 4);
            let header = "";
            for (let i = 0; i < uint.length; i++) {
                header += uint[i].toString(16);
            }
            resolve({ status: true, data: header });
        };

        reader.onerror = function (err) {
            reject({ status: false, data: err });
        };

        reader.readAsArrayBuffer(file.slice(0, byteCount));
    });
};
const validateFileExtensionByFileHeader = async (input) => {
    const validTypes = [
        "image/png",
        "image/jpeg",
        "image/jpeg",
        "image/jpeg",
        "image/bmp",
        "image/tiff",
        "image/webp",
    ];
    const regex = new RegExp(`^(${validTypes.join("|")})$`);
    const header = await getFileHeader(input);
    if (!header.status) return false;
    const extension = fileJson["HEADER_TO_MIME"][header.data];
    if (!extension) return false;
    if (!regex.test(extension)) return false;
    return true;
};
const mountedModule = async () => {
    const response = await getKiosk();
    if (response.err_code === 0)
        getSuccessHandler({ contents: response.contents });
    else alert("정보를 불러오는데 실패했습니다.\n관리자에게 문의해주세요.");
};
onMounted(async () => {
    await mountedModule();
});
</script>
<style scope>
.info-container {
    display: flex;
}
.box-contents {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 7%;
    border-right: 1px solid #e5e7eb;
    min-height: 300px;
}
.box-img {
    width: 40%;
    display: flex;
    justify-content: center;
    min-height: 300px;
}
.info-section {
    padding: 16px 0px;
}
.info-img {
    width: 100%;
    max-width: 500px;
    max-height: 500px;
    object-fit: contain;
}
.kiosk-img{
aspect-ratio: 2160/907;
}
.tablet-img{
aspect-ratio: 16/19;
}
.dp_none {
    display: none;
}
.img_name {
    font-size: 14px;
    font-weight: 400;
}
.info_label {
    font-size: 16px;
    font-weight: 600;
}
.img-info-s {
    font-size: 12px;
    font-weight: 300;
}
.info-cursor-pointer{
    cursor: pointer;
}
</style>
<template>
    <div>
        <div class="adm_box_wrapper table_wrapper">
            <!-- 헤더 섹션 -->
            <section
                class="flex items-baseline justify-between p-5 border-b border-gray-200"
            >
                <h3 class="font-medium">키오스크 및 태블릿 정보 변경</h3>
            </section>

            <!-- 키오스크 및 태블릿 정보 입력 섹션 -->
            <main class="p-5 border-b border-gray-200">
                <!-- 키오스크 정보 -->
                <section
                    class="info-section"
                    style="border-bottom: 1px solid #e5e7eb"
                >
                    <h4 class="mb-3 font-semibold">키오스크</h4>
                    <div id="kiosk-info" class="flex gap-3 space-y-4">
                        <div class="box-contents">
                            <div id="kiosk-image">
                                <label
                                    for="kiosk-image-input"
                                    class="block info_label"
                                    >이미지
                                    <span class="img-info-s">
                                        * 권장비율 2160:907
                                    </span>
                                    <p class="p-2 img_name info-cursor-pointer">
                                        {{ fileNames.kiosk }}
                                    </p>
                                </label>
                                <input
                                    id="kiosk-image-input"
                                    type="file"
                                    class="dp_none"
                                    @change="
                                        (e) => {
                                            changeFile(
                                                e.target.files[0],
                                                'kiosk'
                                            );
                                        }
                                    "
                                />
                            </div>
                            <div id="kiosk-title" style="width: 70%">
                                <label
                                    for="kiosk-title-input"
                                    class="block info_label"
                                    >타이틀</label
                                >
                                <input
                                    id="kiosk-title-input"
                                    type="text"
                                    class="w-full p-2"
                                    placeholder="타이틀을 입력하세요"
                                    v-model="info.kioskTitle"
                                    pattern="[0-9가-힣A-Za-z]{1,13}"
                                    title="타이틀은 13자리까지 입력해주세요"
                                    maxlength="13"
                                />
                            </div>
                            <div id="kiosk-description">
                                <label
                                    for="kiosk-description-input"
                                    class="block info_label"
                                    >설명</label
                                >
                                <input
                                    id="kiosk-description-input"
                                    type="text"
                                    class="w-full p-2"
                                    placeholder="설명을 입력하세요"
                                    pattern="[0-9가-힣A-Za-z]{1,39}"
                                    title="타이틀은 13자리까지 입력해주세요"
                                    maxlength="39"
                                    v-model="info.kioskDescription"
                                />
                            </div>
                        </div>
                        <div class="box-img">
                            <img :src="info.kioskImage" class="info-img kiosk-img" />
                        </div>
                    </div>
                </section>

                <!-- 태블릿 정보 -->
                <section class="mt-8">
                    <h4 class="mb-3 font-semibold">태블릿</h4>
                    <div id="tablet-info" class="flex gap-3 space-y-4">
                        <div class="box-contents">
                            <div id="tablet-image">
                                <label
                                    for="tablet-image-input"
                                    class="block info_label"
                                    >이미지
                                    <span class="img-info-s">
                                        * 권장비율 16:19
                                    </span>
                                    <p class="p-2 img_name info-cursor-pointer">
                                        {{ fileNames.tablet }}
                                    </p>
                                </label>
                                <input
                                    id="tablet-image-input"
                                    type="file"
                                    class="dp_none"
                                    @change="
                                        (e) => {
                                            changeFile(
                                                e.target.files[0],
                                                'tablet'
                                            );
                                        }
                                    "
                                />
                            </div>
                            <div id="tablet-title">
                                <label
                                    for="tablet-title-input"
                                    class="block info_label"
                                    >타이틀</label
                                >
                                <input
                                    id="tablet-title-input"
                                    type="text"
                                    class="w-full p-2"
                                    placeholder="타이틀을 입력하세요"
                                    pattern="[0-9가-힣A-Za-z]{1,13}"
                                    title="타이틀은 13자리까지 입력해주세요"
                                    maxlength="13"
                                    v-model="info.tabletTitle"
                                />
                            </div>
                            <div id="tablet-description">
                                <label
                                    for="tablet-description-input"
                                    class="block info_label"
                                    >설명</label
                                >
                                <input
                                    id="tablet-description-input"
                                    type="text"
                                    class="w-full p-2"
                                    placeholder="설명을 입력하세요"
                                    pattern="[0-9가-힣A-Za-z]{1,39}"
                                    title="타이틀은 13자리까지 입력해주세요"
                                    maxlength="39"
                                    v-model="info.tabletDescription"
                                />
                            </div>
                        </div>
                        <div class="box-img">
                            <img :src="info.tabletImage" class="info-img tablet-img" />
                        </div>
                    </div>
                </section>
            </main>

            <!-- 저장 버튼 섹션 -->
            <footer class="p-5 bg-white">
                <button
                    class="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600"
                    @click="saveKioskTabletInfo"
                >
                    저장
                </button>
            </footer>
        </div>
    </div>
</template>
