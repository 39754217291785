<template>

  <div>
    <loading v-if="state.isLoading"></loading>
    <div class="adm_box_wrapper2">
      <section class="flex justify-between items-baseline p-5 border-b border-gray-200">
        <div>
          <h3 class="font-medium">컨퍼런스 신청자 목록</h3>
          <p class="text-neutral-500">Total : {{state.items.length}} 건</p>
<!--          <p class="text-neutral-500">Total : {{ state.registered.length }}</p>-->
        </div>
      </section>
      <div>
        <div class="p-2 flex justify-between">
          <div>
            <select v-model="state.conferenceIdx" class="border py-1 pl-1 pr-3 text-sm font-bold" @change="conferenceChange">
              <option value="0"> 컨퍼런스 선택 </option>
              <option :value="item.idx" v-for="item in state.conferenceList"> {{item.title}} </option>
            </select>
            <select v-model="state.conferenceDetailIdx" class="border py-1 pl-1 pr-3 ml-1 text-sm font-bold" @change="conferenceDetailChange()">
              <option value="0"> 차수 선택 </option>
              <option :value="item.number" v-for="item in state.conferenceDetailList"> {{item.number}}차 {{item.local}} {{item.place}} </option>
            </select>
          </div>
          <div>
            <select v-model="state.status" @change="getRegistered" class="border p-1 text-sm">
              <option value="all">전체분류</option>
              <option value="결제시도">결제시도</option>
              <option value="등록완료">등록완료</option>
              <option value="입금대기">입금대기</option>
              <option value="등록취소">등록취소</option>
              <option value="결제실패">결제실패</option>
            </select>
            <select v-model="state.searchField" class="border p-1 mx-1 text-sm">
              <option value="name"> 이름(아이디) </option>
              <option value="oid"> 주문번호 </option>
              <option value="church"> 교회명 </option>
              <option value="barcode"> 바코드 </option>
            </select>
            <input type="text" v-model="state.searchValue" class="border p-1 text-sm" placeholder="검색어 입력" @keyup.enter="getRegistered">
            <button type="button" class="sec_blk_btn px-2 py-1 text-sm ml-1" @click="getRegistered"> 검색 </button>

          </div>
        </div>
        <div>
<!--            :search-field="state.searchField"-->
<!--            :search-value="state.searchValue"-->

          <Vue3EasyDataTable
            :headers="headers"
            :items="state.items"
            alternating
          >
            <template #item-select="{idx}">
              <input type="checkbox" v-model="state.selectList" :value="idx">
            </template>
            <template #item-status="{status, statusColor}">
              <span :class="statusColor">{{status}}</span>
            </template>
            <template #item-list="{memberList, headcount}">
              <span class="cursor-pointer" @click="showMemberList(memberList)">[{{headcount}}명]</span>
            </template>
            <template #item-joined_conference="{joined_conference, joined_date}">
              <div v-if="joined_conference === 'Y'" class="text-blue-500">
                <div>등록완료</div>
                <div>{{joined_date}}</div>
              </div>
              <span v-else> - </span>
            </template>

            <template #item-addressCheck="{addressCheck}">
              <div v-if="addressCheck === 'Y'">
                <div>등록완료</div>
              </div>
            </template>

            <template #item-joined_change="{joined_conference, idx}">
              <button type="button" class="sec_blk_btn px-2 py-1" v-if="joined_conference === 'Y'" @click="updateJoined(idx)"> '미등록'으로 변경 </button>
              <span v-else> - </span>
            </template>
            <template #loading>
               로딩중...
            </template>
            <template #empty-message>
              <div class="text-center text-gray-400 text-2xl mt-5"> 컨퍼런스를 선택해 주세요. </div>
            </template>
            <template #expand="{address, addressCheck}" v-show="addressCheck==='Y'">
              <div style="padding: 15px">
                {{address}}
              </div>
            </template>
          </Vue3EasyDataTable>
        </div>
        <div class="p-2 flex items-center justify-between">
          <div class="flex">
            <div class="mr-2"> 알림톡 발송 : </div>
            <div>
              <div>
                <button type="button" class="sec_blk_btn px-2 py-1 text-sm ml-1" v-if="state.conferenceIdx > 0" @click="selectSend"> 선택 신청자 바코드 발송 </button>
                <button type="button" class="sec_blk_btn px-2 py-1 text-sm ml-1" v-if="state.conferenceIdx > 0 && state.conferenceDetailIdx > 0" @click="numberSend"> 선택 컨퍼런스(차수) 바코드 발송 </button>
                <button type="button" class="sec_blk_btn px-2 py-1 text-sm ml-1"  v-if="state.conferenceIdx > 0 && state.conferenceDetailIdx > 0 " @click="surveySend"> 설문 발송 </button>
              </div>
              <div class="mt-2">
                <button type="button" class="sec_blk_btn px-2 py-1 text-sm ml-1"  v-if="state.conferenceIdx === 6 && state.conferenceDetailIdx === 2 " @click="sendOnlineNotify('1')"> 2차 온라인 1차 안내문 알림톡 발송 </button>
                <button type="button" class="sec_blk_btn px-2 py-1 text-sm ml-1"  v-if="state.conferenceIdx === 6 && state.conferenceDetailIdx === 2 " @click="sendOnlineNotify('2')"> 2차 온라인 2차 안내문 알림톡 발송 </button>
              </div>

              <div class="mt-2">
                <button type="button" class="sec_blk_btn px-2 py-1 text-sm ml-1"  v-if="state.conferenceIdx === 13 && state.conferenceDetailIdx === 1 " @click="sendOnlineNotify('1')"> 1차 온라인 1차 안내문 알림톡 발송 </button>
                <button type="button" class="sec_blk_btn px-2 py-1 text-sm ml-1"  v-if="state.conferenceIdx === 13 && state.conferenceDetailIdx === 1 " @click="sendOnlineNotify('2')"> 1차 온라인 2차 안내문 알림톡 발송 </button>
              </div>
            </div>
          </div>


          <div>
            <button type="button" class="sec_blk_btn px-2 py-1 text-sm mr-1" @click="excelDown()">
               목록 내려받기
            </button>
          </div>

        </div>

      </div>



    </div>

    <div class="modal" v-show="state.personListModal === true">
      <div class="overlay" @click="state.personListModal = false"></div>
      <div class="modal-card">
        <div id="AdmModal">
          <div class="flex justify-center mt-5">
            <h5> 신청 인원 </h5>
  <!--          <div><img src="@/assets/img/my_pop_close.svg" class="w-11 max-w-[8vw] cursor-pointer" alt="닫기"></div>-->
          </div>
          <div class="p-5">
<!--            <table class="table-auto w-full">-->
            <table class="min-w-full text-left text-sm font-light">
              <thead class="border-b font-medium dark:border-neutral-500">
                <tr>
                  <th scope="col" class="px-6 py-3 text-center">idx</th>
                  <th scope="col" class="px-6 py-3 text-center">이름</th>
                  <th scope="col" class="px-6 py-3 text-center">교회명</th>
                  <th scope="col" class="px-6 py-3 text-center">상태</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="person in state.personList" class="text-center" :class="{
                  'text-red-500': person.is_cancel === 'Y'
                }">
                  <td class="whitespace-nowrap px-6 py-3 border-b">{{person.idx}}</td>
                  <td class="whitespace-nowrap px-6 py-3 border-b">{{person.name}}</td>
                  <td class="whitespace-nowrap px-6 py-3 border-b">{{person.church}}</td>
                  <td class="whitespace-nowrap px-6 py-3 border-b">
                    <span v-if="person.is_cancel == 'Y'">취소</span>
                    <span v-else-if="person.is_cancel == 'N'">등록</span>
                    <span v-else> - </span>
                    <span v-if="person.is_cancel">({{person.is_cancel}})</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="flex justify-center">
            <button class="sec_filled-blk_btn block w-20 p-2" @click="state.personListModal = false">닫기</button>
          </div>
        </div>
      </div>
    </div>
  </div>



</template>

<script setup>
import {reactive, ref} from "vue"
import axios from "axios"

import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import xlsx from 'xlsx/dist/xlsx.full.min.js';

import loading from "@/components/loading.vue"

const state = reactive({
  registered: [],
  status: 'all',
  currentPage: 1,
  pageSize: 30, //페이지에 30명씩
  searchField: 'name',
  searchValue: '',
  items: [],
  personListModal: false,
  personList: [],
  conferenceIdx: 0,
  conferenceList: [],
  selectList: [],
  conferenceDetailIdx: 0,
  conferenceDetailList: [],
  isLoading: false,
  downData: [],
})

const headers = reactive([
  { text: "선택", value: "select" },
	{ text: "이름(아이디)", value: "name", sortable: true },
	{ text: "전화번호", value: "phone" },
	{ text: "교회명", value: "church" },
	{ text: "신청 컨퍼런스 상세", value: "conference" },
	{ text: "결제상태", value: "status" },
	// { text: "결제수단", value: "method" },
	{ text: "결제금액", value: "price" },
	// { text: "주문번호", value: "oid", sortable: true },
	{ text: "등록일", value: "created_date", sortable: true },
	{ text: "참석자목록", value: "list" },
  { text: "바코드", value: "barcode" },
	{ text: "참여상태", value: "joined_conference", sortable: true },
  { text: "참여변경", value: "joined_change" },
  { text: "배정강의실", value: "roomName" },
  { text: "주소입력유무", value: "addressCheck" },

])

// let items = reactive([]);

const setStatusColor = (status) => {
  switch (status) {
    case '결제시도':
      return 'text-gray-600';
    case '등록완료':
      return 'text-blue-600';
    case '입금대기':
      return 'text-yellow-600';
    case '등록취소':
      return 'text-orange-600';
    case '결제실패':
      return 'text-red-600';
    default:
      return 'text-gray-600';
  }
}

const getRegistered = async () => {
  if( state.conferenceIdx > 0 ){
    state.isLoading = true;   // 로딩 시작
    state.items = [];

    let searchParams = "";
    let url = `/api/registered/conferences/${state.conferenceIdx}?status=${state.status}`;
    if( state.conferenceDetailIdx > 0 ){
      url = `/api/registered/conferences/${state.conferenceIdx}/${state.conferenceDetailIdx}?status=${state.status}`
    }
    if( state.searchValue !== "" ){
      const queryValue = encodeURIComponent(state.searchValue);
      searchParams = `&type=${state.searchField}&value=${queryValue}`;
      if( state.conferenceDetailIdx > 0 ){
        url = `/api/registered/conference/search/${state.conferenceIdx}/${state.conferenceDetailIdx}?status=${state.status}${searchParams}`
      }
      else {
        url = `/api/registered/conference/search/${state.conferenceIdx}?status=${state.status}${searchParams}`;
      }
    }
    const {data} = await axios.get(url);
    const {contents} = data;
    state.downData = contents;    // 엑셀 다운용

    contents.forEach((item) => {
      let statusColor = setStatusColor(item.status);
      let barcode = (item.idx).toString().padStart(4, '0')+''+(item.conferenceDetail.idx).toString().padStart(4, '0')+''+(item.member.idx).toString().padStart(4, '0');
      let setItem = {
        idx: item.idx,
        name: item.name + " (" + item.member.uid + ")",
        church: item.church,
        phone: ( item.phone ) ? formattedPhone(item.phone) : "-",
        conference: item.conferenceDetail.number + "차 " + item.conferenceDetail.local + " " + item.conferenceDetail.place + " " + item.conferenceDetail.dept,
        status: item.status,
        statusColor: statusColor,
        method: "-",
        price: item.total_price.toLocaleString('kr-ko'),
        oid: item.oid,
        created_date: formattedDate(item.created_date),
        headcount: item.headcount,
        list: "-",
        barcode: barcode,
        memberList: item.registerMemberList,
        joined_conference: item.joined_conference,
        joined_date: formattedDate(item.joined_date),
        roomName: item.roomname??'-',
        address: ( item.conferenceDetail.conference.isAddress === 'Y' ) ? `[${item.member.zipCode}] ${item.member.address} ${item.member.detailedAddress}` : '',
        addressCheck: item.conferenceDetail.conference.isAddress
      };
      state.items.push(setItem);
    })

    state.isLoading = false;   // 로딩 종료
  }
}


const excelDown = async () => {

  if( state.downData.length === 0 ){
    alert('목록이 없습니다.');
    return false;
  }
  alert('잠시 후 목록을 내려 받습니다.');
  const downData = state.downData;
  let makeData = [];
  downData.forEach((item) => {
    let setItem = {
      '이름(아이디)': item.name + " (" + item.member.uid + ")",
      '교회명': item.church,
      '전화번호': ( item.phone ) ? formattedPhone(item.phone) : "-",
      '컨퍼런스정보': item.conferenceDetail.number + "차 " + item.conferenceDetail.local + " " + item.conferenceDetail.place + " " + item.conferenceDetail.dept,
      '결제상태': item.status,
      '결제금액': item.total_price.toLocaleString('kr-ko'),
      '등록일': formattedDate(item.created_date),
      '참석자수': item.headcount,
      '주소': ( item.conferenceDetail.conference.isAddress === 'Y' ) ? `[${item.member.zipCode}] ${item.member.address} ${item.member.detailedAddress}` : '',
    };
    makeData.push(setItem);
  })

  console.log(xlsx);

  // 오늘날짜
  const downloadDate = new Date();
  const year = downloadDate.getFullYear();
  const month = (downloadDate.getMonth() + 1).toString().padStart(2, '0');
  const day = downloadDate.getDate().toString().padStart(2, '0');
  // 현재 시간
  const hours = downloadDate.getHours().toString().padStart(2, '0');
  const minutes = downloadDate.getMinutes().toString().padStart(2, '0');
  const seconds = downloadDate.getSeconds().toString().padStart(2, '0');
  const today = year + month + day + hours + minutes + seconds;

  const dataWS = xlsx.utils.json_to_sheet(makeData);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, dataWS, '목록');
  xlsx.writeFile(wb, '컨퍼런스_신청자목록_'+today+'.xlsx');
}


const formattedPhone = (phone) => {
  if (phone.length === 11) {
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1 - $2 - $3');
  } else {
    return phone;
  }
}

const formattedDate = ( date ) => {
  if (date) {
    date = new Date(date);
    const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    return date.toLocaleString('ko-KR', options).replace(',', '');
  }
  else {
    return '-';
  }
}

const updateJoined = async (idx) => {
  if( confirm("참여상태를 '미등록'오로 변경 하시겠습니까?") === true  ){
    const { data } = await axios.put(`/api/registered/conference/join/reset/${idx}`);
    if (data.err_code === 0) {
      alert('참여상태가 변경 되었습니다.');
      await getRegistered();
    }
    else {
      alert('참여상태 변경에 실패하였습니다.');
      document.location.reload();
    }
  }
}

const showMemberList = (memberList) => {
  state.personList = [];
  state.personListModal = true;
  state.personList = memberList;
}


const setConferenceList = async () => {
  const url = "/api/conference/title/list";
  try {
    await axios.get(url).then((res) => {
      const data = res.data;
      if( data.err_code === 0 ){
        state.conferenceList = data.contents;
      }
    });
  }
  catch (e) {
    console.log(e);
  }
}

setConferenceList();

const setConferenceDetailList = async () => {
  state.conferenceDetailIdx = 0;
  if( state.conferenceIdx > 0 ){
    const url = `/api/conference/detail/list/${state.conferenceIdx}`;
    try {
      await axios.get(url).then((res) => {
        const data = res.data;
        if( data.err_code === 0 ){
          state.conferenceDetailList = data.contents;
        }
      });
    }
    catch (e) {
      console.log(e);
    }
  }
}

const conferenceChange = async () => {
  await setConferenceDetailList();
  await getRegistered();
}

const conferenceDetailChange = async () => {
  await getRegistered();
}

// 선택한 참가자에게 발송
const selectSend = async () => {
  if( confirm('선택한 신청자에게 바코드 알림톡을 전송 하시겠습니까?') === true ){
    state.isLoading = true;   // 로딩 시작
    const barcodeList = state.selectList;
    if( barcodeList.length > 0 ){
      const url = `/api/registered/conference/send-msg-barcode`;
      const body = {barcodeList: state.selectList};
      await axios.post(url, body).then((res) => {
        const data = res.data;
        if( data.err_code === 0 ){
          alert('발송되었습니다.');
        }
        else {
          alert('발송에 실패하였습니다.');
        }
        state.isLoading = false;   // 로딩 종료
        return false;
      })
    }
    else {
      alert('발송할 신청자를 선택해 주세요.');
      state.isLoading = false;   // 로딩 종료
      return false;
    }
  }
}

// 선택한 컨퍼런스 전체 발송
const numberSend = async () => {
  if( confirm('선택한 컨퍼런스의 차수에 해당하는 신청자에게\n\n바코드 알림톡을 전송 하시겠습니까?') === true ) {
    state.isLoading = true;   // 로딩 시작
    const conferenceIdx = state.conferenceIdx;
    const conferenceDetailIdx = state.conferenceDetailIdx;
    if( conferenceIdx > 0 && conferenceDetailIdx > 0 ){
      const url = `/api/registered/conference/send-msg-barcode/${conferenceIdx}/${conferenceDetailIdx}`;
      await axios.post(url, {}, {
        timeout: 1000 * 60 * 10    // 10분
      }).then((res) => {
        const data = res.data;
        if( data.err_code === 0 ){
          alert('발송되었습니다.');
        }
        else {
          alert('발송에 실패하였습니다.');
        }
        state.isLoading = false;   // 로딩 종료
        return false;
      })
      .catch((err) => {
        console.log(err);
        if( err.code === 'ECONNABORTED' ){
          alert('서버로 부터 응답이 없습니다.\n\n비즈엠 사이트에서 발송을 확인 해주세요.');
        }
        else {
          alert('발송에 실패하였습니다.');
        }
        state.isLoading = false;   // 로딩 종료
        return false;
      });
    }
    else {
      alert('컨퍼런스와 차수를 선택해 주세요.');
      state.isLoading = false;   // 로딩 종료
      return false;
    }
  }
}

// 하나바이블 설문 알림톡 발송
const surveySend = async () => {
  if( confirm('설문 알림톡을 발송 하시겠습니까?') === true ){
    state.isLoading = true;   // 로딩 시작
    const conferenceIdx = state.conferenceIdx;
    const conferenceDetailIdx = state.conferenceDetailIdx;
    if( conferenceIdx > 0 && conferenceDetailIdx > 0 ){
      const url = `/api/registered/conference/send-msg-schedule/${conferenceIdx}/${conferenceDetailIdx}`;
      await axios.get(url, {
        timeout: 1000 * 60 * 10    // 10분
      }).then((res) => {
        const data = res.data;
        if( data.err_code === 0 ){
          alert('발송되었습니다.');
        }
        else {
          alert('발송에 실패하였습니다.');
        }
        state.isLoading = false;   // 로딩 종료
        return false;
      })
      .catch((err) => {
        console.log(err);
        if( err.code === 'ECONNABORTED' ){
          alert('서버로 부터 응답이 없습니다.\n\n비즈엠 사이트에서 발송을 확인 해주세요.');
        }
        else {
          alert('발송에 실패하였습니다.');
        }
        state.isLoading = false;   // 로딩 종료
        return false;
      });
    }
    else {
      alert('컨퍼런스를 선택해 주세요.');
      state.isLoading = false;   // 로딩 종료
      return false;
    }
  }
}

const sendOnlineNotify = async ( infoNumber ) => {
  const confirmMsg = `1차 온라인 ${infoNumber}차 안내문 알림톡을 발송 하시겠습니까?`;
  if( confirm(confirmMsg) === true ){
    state.isLoading = true;   // 로딩 시작
    const conferenceIdx = state.conferenceIdx;
    const conferenceDetailIdx = state.conferenceDetailIdx;
    if( conferenceIdx > 0 && conferenceDetailIdx > 0 ){
      let url = '';
      if( infoNumber === '1' ){
        url = `/api/registered/conference/send-msg-online-first/13`;
      }
      else if( infoNumber === '2' ){
        url = `/api/registered/conference/send-msg-online-second/13`;
      }
      if( url !== '' ){
        await axios.post(url, {
          timeout: 1000 * 60 * 10    // 10분
        }).then((res) => {
          const data = res.data;
          if( data.err_code === 0 ){
            alert('발송되었습니다.');
          }
          else {
            alert('발송에 실패하였습니다.');
          }
          state.isLoading = false;   // 로딩 종료
          return false;
        })
        .catch((err) => {
          console.log(err);
          if( err.code === 'ECONNABORTED' ){
            alert('서버로 부터 응답이 없습니다.\n\n비즈엠 사이트에서 발송을 확인 해주세요.');
          }
          else {
            alert('발송에 실패하였습니다.');
          }
          state.isLoading = false;   // 로딩 종료
          return false;
        });
      }
    }
    else {
      alert('컨퍼런스를 선택해 주세요.');
      state.isLoading = false;   // 로딩 종료
      return false;
    }
  }

}


</script>

<style scoped>
/* Modal */
.modal,
.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  z-index: 2;
}
.overlay {
  opacity: 0.5;
  background-color: black;
}
.modal-card {
  position: relative;
  margin: auto;
  width: 500px;
  background-color: white;
  z-index: 10;
  opacity: 1;
}
#AdmModal {
  margin-bottom: 15px;
}
.close {
  cursor: pointer;
}
.btn-black-100 {
  cursor: pointer;
}
</style>
