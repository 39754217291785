<template>
  <section class="bg-white dark:bg-gray-900">
    <div class="lg:py-16 px-4 mx-auto max-w-screen-md text-center">
      <img src="@/assets/img/logo.png" alt="총회교육개발원" class="mx-auto mb-4"/>
      <p class="font-medium mb-2 text-[4vw] break-keep">{{ title }}</p>

      <div v-if="theValue==='5'">
        <div class="my-10">
          <a href="https://us05web.zoom.us/j/86002826598?pwd=G4NisNbUSK6NIVUwzeOC2HJwy1boEr.1" target="_blank">
            <button type="button" class="btn btn-blue">
              줌 회의 참여
            </button>
          </a>
        </div>

        <div>
          <p> 회의 ID : <b class="text-2xl">860 0282 6598</b> </p>
          <p> 암호 : <b class="text-2xl">HOLYBIBLE</b> </p>
        </div>
      </div>

      <div v-if="theValue==='6'">
        <div class="my-10">
          <a href="https://us06web.zoom.us/j/86936813330?pwd=slOUQPa1O75JGkiNmaSWlrkqdaGJhE.1" target="_blank">
            <button type="button" class="btn btn-blue">
              줌 회의 참여
            </button>
          </a>
        </div>

        <div>
          <p> 회의 ID : <b class="text-2xl">869 3681 3330</b> </p>
          <p> 암호 : <b class="text-2xl">0106</b> </p>
        </div>
      </div>

      <div v-if="theValue==='13'">
        <div class="my-10">
          <a href="https://us05web.zoom.us/j/7104903924?pwd=oamEe6dhzcbV3y8Bx6Ou8hWOHHYCRQ.1&omn=87528040319" target="_blank">
            <button type="button" class="btn btn-blue">
              줌 회의 참여
            </button>
          </a>
        </div>

        <div>
          <p> 회의 ID : <b class="text-2xl">710 490 3924</b> </p>
          <p> 암호 : <b class="text-2xl">HOLYBIBLE</b> </p>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import axios from "axios";
import { useRoute } from 'vue-router'


export default {
  name: "barcodeComponent",

  data() {
    return {
      theValue : 0,
      title: ""
    }
  },

  methods: {
    init(){
      axios.get('/api/conference/' + this.theValue).then(response => {
        const data = response.data;
        if(data.err_code==0 || data.err_code==100){
          this.title = data.contents.title;
        }
        else{
          alert(data.err_msg);
        }

      }).catch(error => {
        console.error(error)
      })
    },
  },
  mounted() {
    const route = useRoute()
    this.theValue = route.params.idx;
    this.init();
  }
}
</script>

<style scoped>
.conf_info {
  margin-bottom: 2.5rem;
}
.conf_info p {
  font-size: 4vw;
  padding: 0 0.5rem;
}
.conf_info_tt {
  background: #f2faff;
  border: 1px solid #039dff;
  color: #039dff;
  text-align: center;
  font-size: 4.25vw;
  font-weight: 500;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
}

b {
  font-weight: 500;
}

.btn {
  @apply font-bold py-2 px-4 rounded text-3xl;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}

</style>