<template>
  <NavComponent/>
  <div class="adm_container">
    <div class="border-r adm_aside border-neutral-200" id="side-menu">
      <ul><!--<span class="title">교육 관리</span>-->
        <li v-for="(page, idx) in state.pages"
            :key="idx"
            @click="[changePage(page.name), toggle(idx)]"
            :class="{ active: state.isActive[idx] }">
          {{ page.text }}
        </li>
      </ul>
    </div>
    <keep-alive class="w-full bg-gray-100 adm_lists">
      <component v-bind:is="state.page"></component>
    </keep-alive>
  </div>
  
</template>

<script>
// @ is an alias to /src

import NavComponent from "@/components/AdminNavComponent.vue";
import EduCreatePage from "@/pages/management/EduCreatePage.vue";
import RegisteredMembersPage from "@/pages/management/RegisteredMembersPage.vue";
import RegisteredMembersPage2 from "@/pages/management/RegisteredMembersPage2.vue";
import EduListManagementPage from "@/pages/management/EduListManagementPage.vue";
import MemberManagementPage from "@/pages/management/MemberManagementPage.vue";
import MemberManagementPage2 from "@/pages/management/MemberManagementPage2.vue";
import ConfCancelPage from "@/pages/management/ConfCancelPage.vue";
import ConfCancelPage2 from "@/pages/management/ConfCancelPage2.vue";
import QnaListPage from "@/pages/management/QnaListPage.vue";
import KioskTabletInfo from "@/pages/management/KioskTabletInfo.vue";
import {reactive} from "vue";

export default {
  name: 'EduManagementView',
  components: {
    MemberManagementPage,
    MemberManagementPage2,
    EduListManagementPage,
    RegisteredMembersPage,
    RegisteredMembersPage2,
    EduCreatePage,
    ConfCancelPage,
    ConfCancelPage2,
    QnaListPage,
    NavComponent,
    KioskTabletInfo
  },
  setup () {
    const state = reactive({
      page: 'EduListManagementPage',
      isActive: [true, false, false, false],
      pages: [
        {
          name: 'EduListManagementPage',
          text: '컨퍼런스 목록'
        },
        {
          name: 'EduCreatePage',
          text: '컨퍼런스 등록'
        },
        /*{
          name: 'MemberManagementPage',
          text: '회원 목록'
        },*/
        {
          name: 'MemberManagementPage2',
          text: '회원 목록'
        },
        /*{
          name: 'RegisteredMembersPage',
          text: '컨퍼런스 신청자 목록'
        },*/
        {
          name: 'RegisteredMembersPage2',
          text: '컨퍼런스 신청자 목록'
        },
        /*{
          name: 'ConfCancelPage',
          text: '컨퍼런스 취소 신청 목록'
        },*/
        {
          name: 'ConfCancelPage2',
          text: '컨퍼런스 취소 신청 목록'
        },
        {
          name: 'QnaListPage',
          text: '문의 목록'
        },
        {
          name: 'KioskTabletInfo',
          text: '키오스크 태블릿 정보 변경'
        }
      ]
    })

    const changePage = (pageName) => {
      state.page = pageName
    }

    const toggle = (idx) => {
      for(let i=0; i<state.pages.length; i++) {
        state.isActive[i] = i===idx ? true : false
      }
    }

    return { state, changePage, toggle }
  }
}
</script>

<style scoped>

.title {
  font-size: large;
  font-weight: bold;
  margin-left: 5px;
  margin-bottom: 5px;
  display: inline-block;
}
ul {
  padding-top: 10px;
  border-bottom: 1px solid #eee;
}
ul li {
  list-style: none;
  padding: 0.65rem 1.25rem;
}
ul li:hover {
  cursor: pointer;
}
.active {
  background: rgb(29 78 216);
  color: white;
}
</style>
